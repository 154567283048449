import { FC } from "react";
import Layout from "../../components/layout/layout";
import Intro from "../../components/intro/intro";
import About from "../../components/about/about";
import Video from "../../components/video/video";
import Questions from "../../components/questions/questions";
import Guidance from "../../components/guidance/guidance";
import Actors from "../../components/actors/actors";
import React from "react";

interface Props {}

const Home: FC<Props> = ({}) => {
  return (
    <>
      <Layout>
        <Intro />
        <Video />
        <About />
        <Guidance />
        <Questions />
        <Actors />
      </Layout>
    </>
  );
};

export default Home;
