import { FC } from "react";
import { FaIcon, FaIcons, Link } from "@in/component-library";
import css from "./guidance.module.scss";

interface GuidanceProps {}

const Guidance: FC<GuidanceProps> = (props) => {
  return (
    <div className={css["guidance"]}>
      <div className={css["guidance--content"]}>
        <h2>Hvordan fungerer tjenesten?</h2>
        <div className={css["guidance__item"]}>
          <FaIcon name={FaIcons.FileLight} className={css["guidance__icon"]} />
          <p>
            Det eneste du trenger å gjøre er å svare på noen spørsmål og fortelle
            oss hva du trenger hjelp til.
          </p>
        </div>
        <div className={css["guidance__item"]}>
          <FaIcon
            name={FaIcons.EnvelopeLight}
            className={css["guidance__icon"]}
          />
          <p>
            Etter 1-2 arbeidsdager får du en tilbakemelding. Der forteller vi deg
            hvem du burde snakke med.
          </p>
        </div>
        <div className={css["guidance__item"]}>
          <FaIcon name={FaIcons.PenLight} className={css["guidance__icon"]} />
          <p>
            <span>
              <Link href="https://raskavklaring.innovasjonnorge.no?from_system=enveiinn.no">
                Fyll ut dette skjemaet.
              </Link>
            </span>
            <span> </span>
            <span>(Det tar ca. 15 min å utfylle)</span>
          </p>
        </div>
        </div>
    </div>
  );
};

export default Guidance;
