import { FC } from "react";
import css from "./about.module.scss";
import ImageBlock from "../image-block/image-block";

interface AboutProps {}

const About: FC<AboutProps> = (props) => {
  return (
    <div className={css["about"]}>
      <div className={css["about--content"]}>
        <div className={css["about__image"]}>
          <img src="/enveiinn_image.jpg" alt="To mennesker på tur i fjellet." />
        </div>
        <div className={css["about__text"]}>
          <h2>Få riktig hjelp raskere</h2>
          <p>
            Det er mange som jobber for at nye forretningsidéer og etablerte
            bedrifter skal lykkes. Likevel oppleves det som komplisert å få
            oversikt over hvem som kan hjelpe. Det har vi nå gjort enklere.
          </p>
          <p>
            "Én vei inn" er din digitale inngang til relevant hjelp,
            finansiering og kompetanse.
          </p>
        </div>
        <div className={css["about__image-mobile"]}>
          <ImageBlock
            imageSrc="/enveiinn_image.jpg"
            altText="To mennesker på tur i fjellet."
          />
        </div>
      </div>
    </div>
  );
};

export default About;
