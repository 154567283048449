import { Link } from "@in/component-library";
import React from "react";
import { FC } from "react";
import css from "./layout.module.scss";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div className={css["layout"]}>
      <div className={css["layout__header"]}>
        <a href="/">
          <img
            src="/PrimLogo_1.png"
            alt="En vei inn logo"
            aria-label="Én vei inn logo"
          />
        </a>
      </div>
      {/* <Header
        className={css["layout__header"]}
        logoAriaLabel="Én vei inn"
        logoLink="/"
        navItems={[
          {
            href: "#",
            onClick: () => navigate("/actors-overview"),
            text: "Aktøroversikt",
          },
          {
            href: "#",
            onClick: () => navigate("/about-page"),
            text: "Om Én vei inn",
          },
          { href: "#", onClick: () => {}, text: "Historier" },
          { href: "#", onClick: () => {}, text: "Min side" }, // ikon
        ]}
        logoImgSrc=""
      /> */}
      <main id="maincontent" className={css["layout__main"]}>
        {children}
      </main>

      <footer className={css["layout__footer"]}>
        <div className={css["layout__footer-first-column"]}>
          <p>
            Denne tjenesten er utviklet av Innovasjon Norge
            <br />
            på oppdrag fra Nærings- og fiskeridepartementet
          </p>
        </div>
        <div className={css["layout__footer-last-column"]}>
          <h2>Om nettsiden</h2>
          <ul>
            <li>
              <Link href="https://www.innovasjonnorge.no/artikkel/personvernerklaering">
                Personvernserklæring
              </Link>
            </li>
            <li>
              <Link href="https://uustatus.no/nn/erklaringer/publisert/3bc2925d-71a2-4b50-939b-73420893b646">
                Tilgjengelighetserklæring
              </Link>
            </li>
            <li>
              Dette nettstedet bruker <Link href="/cookies">cookies</Link>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
