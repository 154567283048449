import React from 'react';
import Layout from '../layout/layout';
import css from './cookieList.module.scss';

const CookieList: React.FC = () => {
	return (
		<Layout>
			<div className={css["cookie-list"]}>
			<h2>Cookies</h2>
			<p>Du må skru av AdBlock og andre blokkeringstjenester i nettleser for å kunne se listen med cookies.</p>
			{/* OneTrust Cookies List start */}
			<div id="ot-sdk-cookie-policy"></div>
			{/* OneTrust Cookies List end */}
			</div>
		</Layout>
	);
};

export default CookieList;