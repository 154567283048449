import { FC } from "react";
import css from "./questions.module.scss";
import { Accordion } from "@in/component-library";

interface QuestionsProps {}

const Questions: FC<QuestionsProps> = ({}) => {
  const questions = [
    {
      question: 'Hva er "Én vei inn"?',
      answer:
        'Enten du er i startfasen, vokser eller ønsker å utvide internasjonalt, finnes det offentlige aktører som kan hjelpe. Med "Én vei inn" gjør vi det enklere og raskere å identifisere riktig støtte. Du skal slippe å sette deg inn i hva hver enkelt aktør kan bistå med. Det skal vi gjøre for deg. Dette initiativet er en del av en større satsning fra Nærings- og fiskeridepartementet med mål om å gjøre virkemiddelapparatet mer tilpasset næringslivets behov. Lurer du på hva virkemiddelapparatet er? Kort fortalt er det offentlige aktører som gjennom blant annet finansiering, forskning, og kompetanse skal bidra til å skape verdi i næringslivet.',
    },
    {
      question: "Hvem er denne tjenesten for?",
      answer:
        "Brukerne av løsningen er blant annet førstegangsgründere med nye idéer, større forskningsprosjekter og etablerte suksesser som vil teste markedsmulighetene i andre land. Det finnes offentlig hjelp uavhengig av hvilken fase du er i. Derfor vil også denne tjenesten dekke en stor variasjon av kunder.",
    },
    {
      question: "Hvorfor skal jeg bruke tjenesten?",
      answer:
        '"Én vei inn" gir deg rask tilgang til relevant offentlig hjelp. Vi forteller deg hvilke aktører som kan hjelpe deg. På den måten kan du bruke tiden din på det som skaper verdi for deg.',
    },
    {
      question: "Hva slags hjelp kan jeg få?",
      answer:
        "Det offentlige kan bistå med både veiledning, kompetanse, kapital og nettverk. Hva slags hjelp du kan få avhenger av hva du jobber med. Det kan for eksempel være veiledning hos en lokal etablerertjeneste, finansiering av forsknings- og utviklingsaktiviteter, eller lån til eksportbedrifter. Mulighetene er mange, og vi hjelper deg med å finne frem.",
    },
    {
      question: "Hvor lang tid tar det å fylle ut skjemaet?",
      answer: "I snitt tar det 15 minutter å fylle ut skjemaet.",
    },
    {
      question: "Når får jeg svar på henvendelsen?",
      answer: "Vi svarer deg i løpet av 1-2 arbeidsdager.",
    },
    {
      question: "Er dette en søknad?",
      answer:
        "Nei, dette er ikke en søknad. Vi henviser deg til relevant hjelp og mulige støtteordninger, men vi kan ikke gi økonomisk støtte.",
    },
  ];

  return (
    <div className={css["questions"]}>
      <div className={css["questions--content"]}>
        <h2>Ofte stilte spørsmål</h2>
        <div className={css["questions__list"]}>
          {questions.map((question, index) => (
            <Accordion title={question.question} id={index}>
              <p>{question.answer}</p>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
