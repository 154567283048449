import { FC } from "react";
import css from "./actors.module.scss";

interface ActorsProps {}

const Actors: FC<ActorsProps> = (props) => {
  const actors = [
    {
      imgSrc: "/actors-logo/in_logo.png",
      alt: "Innovasjon Norge",
    },
    {
      imgSrc: "/actors-logo/Enova_logo.png",
      alt: "Enova",
    },
    {
      imgSrc: "/actors-logo/Forskningsradet_Logo_Bokmal_01_Weller_RGB.png",
      alt: "Forskningsrådet",
    },
    {
      imgSrc: "/actors-logo/Eksfin-logo_nor_RGB_stor.png",
      alt: "Eksfin",
    },
    {
      imgSrc: "/actors-logo/Innlandet_fylkeskommune_liggende_pos_RGB.png",
      alt: "Innlandet fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/002799.png",
      alt: "Møre og Romsdal fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/nfi_main_logo_nor.png",
      alt: "Norsk filminstitutt",
    },
    {
      imgSrc: "/actors-logo/doga-logo_norsk_sort.png",
      alt: "Design and Architecture Norway",
    },
    {
      imgSrc: "actors-logo/Trondelag-logo.png",
      alt: "Trøndelag fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Oslo-logo-sort-RGB.png",
      alt: "Oslo kommune",
    },
    {
      imgSrc: "/actors-logo/siva_logo2018_col.png",
      alt: "Siva",
    },
    {
      imgSrc: "/actors-logo/vestland-fylkeskommune_rgb.png",
      alt: "Vestland fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Patentstyret-primaer-svart-rgb.png",
      alt: "Patentstyret",
    },
    {
      imgSrc: "/actors-logo/Logo_nfk_gjennomsiktig.png",
      alt: "Nordland fylkeskommune",
    },
    //mangler riktig logo
    {
      imgSrc: "/actors-logo/agder_logo.png",
      alt: "Agder fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Kulturdirektoratet_svart.png",
      alt: "Kulturdirektoratet",
    },
    {
      imgSrc: "/actors-logo/Østfold-fylkeskommune-logo.png",
      alt: "Østfold fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Akershus-fylkeskommune_logo.png",
      alt: "Akershus fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/v_logo_02_sidestilt_rgb.png",
      alt: "Vestfold fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/t_logo_02_sidestilt_rgb.png",
      alt: "Telemark fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Buskerud-fylkeskommune_Sort.png",
      alt: "Buskerud fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Rogfk_Hovedlogo_2000px_RGB.png",
      alt: "Rogaland fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Troms_fylkeskommune_farge_liggende.png",
      alt: "Troms fylkeskommune",
    },
    {
      imgSrc: "/actors-logo/Hovedlogo_FFK_farger_trykk.png",
      alt: "Finnmark fylkeskommune",
    },
  ];

  return (
    <div className={css["actors"]}>
      <div className={css["actors--content"]}>
        <h2>Vi fremmer norsk næringsliv</h2>
        <div className={css["actors__list"]}>
          {actors.map((actor) => (
            <div className={css["actors__item"]}>
              <img
                src={actor.imgSrc}
                className={css["actors__image"]}
                alt={actor.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Actors;
