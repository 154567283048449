import { FC, useRef, useState } from "react";
import css from "./video.module.scss";
import ImageBlock from "../image-block/image-block";
import { FaIcon, FaIcons, IconSize } from "@in/component-library";

interface AboutProps {}

const Video: FC<AboutProps> = (props) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const konseptRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={konseptRef} className={css["video"]}>
      <video className={css["video__player"] + (showVideo ? " " + css["video__player--disapare"] : "")} src="EnVeiInn_Explainer_muted.mp4" autoPlay muted loop disablePictureInPicture tabIndex={-1} />
      <div className={css["video--content"]}>
      <h2>Én vei inn</h2>
      <p>Se hvordan vi kan hjelpe deg</p>
        {
          showVideo ? <video src="EnVeiInn_Explainer.mp4" autoPlay controls className={css["video__actualPlayer"]}/> : (<button className={css["video__play-btn"]} onClick={() => {setShowVideo(true); konseptRef.current?.scrollIntoView({
            behavior: 'smooth'
        });}}>
          <FaIcon name={FaIcons.PlayLight} size="xl"/>
          <p>Spill av video</p>
        </button>)
        }
      </div>
    </div>
  );
};

export default Video;
