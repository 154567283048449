import { FC } from "react";
import css from "./image-block.module.scss";

interface ImageBlockProps {
  imageSrc: string;
  altText: string;
}

const ImageBlock: FC<ImageBlockProps> = ({ imageSrc, altText }) => {
  return (
    <>
      <div className={css["image-block"]}>
        <img src={imageSrc} alt={altText} />
      </div>
    </>
  );
};

export default ImageBlock;
